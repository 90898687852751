.homepage {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 45em;
  /* padding-bottom: 3em; */
  background-color: #d7dcfa;
}
.toolbar-text {
  display: flex;
  height: 1em;
  flex-direction: row;
  font-weight: bold;
  font-size: 1.5em;
  text-shadow: rgb(9, 32, 82) 0px 0px 1px;
}
.main-header-wrap {
  background-color: "transparent";
}
.main-header-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 35em;
  text-align: center;
  color: #1b32b6;
  background-color: rgb(0, 0, 0, 0.3);
  width: 100%;
  justify-content: center;
}
.main-header-icons {
  height: 100%;
  position: absolute;
  left: 5%;
  top: 93%;
}
.main-header-a-icons {
  color: #2d4cfc;
}
.body-wrap {
  padding: 1em;
  width: 100%;
  z-index: 1;
}
.bg-image {
  width: 100%;
  position: sticky;

  left: 0;
  top: 0;
}
.skill-intro {
  font-size: 1.8em;
  font-weight: bold;
  padding-top: 1%;
  padding-left: 0.5em;
  color: white;
  text-shadow: rgb(9, 26, 82) 0px 0px 1px;
}
.list-header {
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: -0.2em;
  color: #0e1f7a;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 1.6em;
}
.list-sub-header {
  color: #0e1f7a;
  font-weight: 600;
  font-style: italic;
  font-size: 1.1em;
}
.list-item {
  color: #0e1f7a;
  font-weight: 500;

  margin-bottom: 1.2em;
}
.list-item-desc {
  padding-left: 1em;
  padding-right: 2em;
  font-weight: 400;
  color: #0e1f7a;
}

.about-me-desc {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 1em;
  padding-top: 1.4em;
  padding-right: 1em;
  padding-bottom: 1.4em;
  text-align: left;
  font-size: 1.1em;
  color: #0e1f7a;
}
.about-picture-wrapper {
  justify-content: flex-end;
  padding-right: 1em;
  align-self: center;
}
.about-picture {
  width: 100%;
}
.card-title {
  font-weight: bold;
  color: #0e1f7a;
  text-align: center;
}
.card-sub-desc {
  text-align: center;
  font-weight: 200;
  color: #0e1f7a;
}

.card-body-desc {
  text-align: justify;
  color: #0e1f7a;
}
.footer {
  color: white;
  background-color: #0e1f7a;
  height: 4em;
  width: 100%;
  display: flex;
  flex: 1;
  /* padding-bottom: 3em; */
  margin-top: 3em;
  justify-content: center;
}
